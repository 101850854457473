<template>
  <div class="content">
    <Header />
    <div class="info">
      <div class="pricesList flex fjcS">
        <div class="sailerList">
          <ul>
            <li
              v-for="(item, key) in sailerList"
              :key="key"
              :data-key="key"
              :class="{ active: sailerId == item.id }"
              :data-id="item.id"
              @click="setSaler"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="prices">
          <div class="form ui flex fWrap fjcS">
            <div class="row col50">
              <span>Цена товара 0 - 1500 наценка, %</span>
              <input type="text" v-model="price_0_1500" />
            </div>
            <div class="row col50">
              <span>Цена товара 1500 - 10000 наценка, %</span>
              <input type="text" v-model="price_1500_10000" />
            </div>
            <div class="row col50">
              <span>Цена товара 10000 - 20000 наценка, %</span>
              <input type="text" v-model="price_10000_20000" />
            </div>
            <div class="row col50">
              <span>Цена товара 20000 - 40000 наценка, %</span>
              <input type="text" v-model="price_20000_40000" />
            </div>
            <div class="row col50">
              <span>Цена товара 40000 - 60000 наценка, %</span>
              <input type="text" v-model="price_40000_60000" />
            </div>
            <div class="row col50">
              <span>Цена товара > 60000 наценка, %</span>
              <input type="text" v-model="price_60000" />
            </div>
            <div class="row col25">
              <a href="#!" class="btn" @click.prevent="savePrice">Сохранить</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/components/app/api";
//import DropDown from "@/components/app/dropDown";
//import Config from "@/components/app/config";
import Helper from "@/components/app/helper";
import Header from "@/components/app/header";
export default {
  name: "Home",
  data() {
    return {
      sailerList: [],
      sailerId: 0,
      price_0_1500: 0,
      price_1500_10000: 0,
      price_10000_20000: 0,
      price_20000_40000: 0,
      price_40000_60000: 0,
      price_60000: 0,
      key: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    async list() {
      this.sailerList = await api.methods.SALERS_list();
    },
    setSaler(e) {
      this.sailerId = e.target.dataset.id;
      this.key = e.target.dataset.key;
      this.price_0_1500 = this.sailerList[this.key].over_0_1500;
      this.price_1500_10000 = this.sailerList[this.key].over_1500_10000;
      this.price_10000_20000 = this.sailerList[this.key].over_10000_20000;
      this.price_20000_40000 = this.sailerList[this.key].over_20000_40000;
      this.price_40000_60000 = this.sailerList[this.key].over_40000_60000;
      this.price_60000 = this.sailerList[this.key].over_60000;
    },
    async savePrice() {
      if (this.sailerId==0) {
        Helper.methods.warning("Вы не выбрали поставщика", "error");
        return;
      }
      this.sailerList[this.key].over_0_1500 = this.price_0_1500;
      this.sailerList[this.key].over_1500_10000 = this.price_1500_10000;
      this.sailerList[this.key].over_10000_20000 = this.price_10000_20000;
      this.sailerList[this.key].over_20000_40000 = this.price_20000_40000;
      this.sailerList[this.key].over_40000_60000 = this.price_40000_60000;
      this.sailerList[this.key].over_60000 = this.price_60000;
      let response = await api.methods.SALER_add({
        id: this.sailerId,
        over_0_1500: this.price_0_1500,
        over_1500_10000: this.price_1500_10000,
        over_10000_20000: this.price_10000_20000,
        over_20000_40000: this.price_20000_40000,
        over_40000_60000: this.price_40000_60000,
        over_60000: this.price_60000
      });
      Helper.methods.warning(response, "ok")
    },
  },
  async mounted() {
    this.list();
  },
  async created() {},
  components: {
    Header,
  },
};
</script>